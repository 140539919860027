import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n.js'
import router from './router'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		active_language: i18n.locale,
		languages: i18n.availableLocales,
		menu: false,
		consent: false,
		links: []
	},
	getters: {
		get_language: state => state.active_language,
		get_languages: state => state.languages,
		get_menu: state => state.menu,
		get_consent: state => state.consent,
		get_links: state => state.links
	},
	mutations: {
		change_lang(state, language) {
			state.active_language = language
			i18n.locale = language
			document.documentElement.lang = language
		},
		set_links(state, links) {
			state.links = links
		},
		set_menu(state, data) {
			state.menu = data
		},
		set_consent(state, data) {
			state.consent = data
		}
	},
	actions: {
		language({ commit, dispatch }, { lang }) {
			commit('change_lang', lang)
			Vue.$ga.event({
				eventCategory: 'language',
				eventAction: 'click',
				eventLabel: lang
			})
			dispatch('create_links')
		},
		create_links({ commit }) {
			let the_links = []
			router.options.routes.forEach(({ name, path, meta }) => {
				if (name) {
					the_links.push({
						name: name,
						path: path,
						icon: meta.icon,
						lang: meta.language
					})
				}
			})
			commit('set_links', the_links)
		},
		toggle_menu({ commit, getters }) {
			commit('set_menu', !getters.get_menu)
		},
		cookie_consent({ commit, getters, dispatch }) {
			commit('set_consent', !getters.get_consent)
			dispatch('check_cookie_consent')
		},
		check_cookie_consent({ getters }) {
			if (getters.get_consent) {
				Vue.$ga.enable()
			} else {
				Vue.$ga.disable()
			}
		}
	},
	plugins: [createPersistedState()]
})
