import Vue from 'vue'
import Router from 'vue-router'

import i18n from './i18n'
import store from './store'

let routes = [{ path: '/', redirect: '/sv' }]

const base_url = 'https://adamkillander.se'

let lang = store.state.active_language ? store.state.active_language : 'sv'
let alt_lang = store.state.languages.find(lng => lang !== lng)

let pages = ['home', 'portfolio', 'about']

pages.forEach((page, index) => {
	let pageUrl =
		i18n.t(`pages[${index}].component`) === 'home'
			? `${base_url}/${lang}`
			: `${base_url}/${lang}/${page}`

	let page_path = page === 'home' ? `` : `/${page}`

	routes.push({
		path: `/:lang${page_path}`,
		name: `pages[${index}].link`,
		component: () =>
			import(/* webpackChunkName: "[request]" */ `./views/${page}.vue`),
		meta: {
			index: index,
			title: `pages[${index}].seo.title`,
			icon: `pages[${index}].icon`,
			language: lang,
			tags: [
				{
					name: 'description',
					content: `pages[${index}].seo.description`
				},

				// Facebook
				{ property: 'og:title', content: `pages[${index}].seo.title` },
				{
					property: 'og:description',
					content: `pages[${index}].seo.description`
				},
				{ property: 'og:site_name', content: `common.author` },
				{ property: 'og:type', content: 'website' },
				{ property: 'og:url', content: pageUrl },
				{ property: 'og:locale', content: `common.lang-code` },
				// Twitter
				{ name: 'twitter:title', content: `pages[${index}].seo.title` },
				{
					name: 'twitter:description',
					content: `pages[${index}].seo.description`
				},
				{ name: 'twitter:creator', content: '@adamkillander96' },
				{ name: 'twitter:site', content: pageUrl },
				{ name: 'twitter:card', content: 'summary' },
				// Other
				{ itemprop: 'name', content: `pages[${index}].seo.title` },
				{
					itemprop: 'description',
					content: `pages[${index}].seo.description`
				},
				{ rel: 'canonical', href: base_url + `/${lang}${page_path}` },
				{
					rel: 'alternate',
					hreflang: `common.lang-code`,
					href: base_url + `/${alt_lang}${page_path}`
				}
			]
		}
	})
})

Vue.use(Router)

const router = new Router({
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (from.meta.language !== to.meta.language) {
			store.dispatch('language', {
				path: to.path,
				lang: to.meta.language
			})
		}
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	base: '/',
	routes: routes,
	linkActiveClass: 'active',
	linkExactActiveClass: '',
	exactActiveClass: 'false'
})

router.beforeEach((to, from, next) => {
	let to_lang = to.params.lang
	if (i18n.locale !== to_lang) {
		store.dispatch('language', {
			lang: to_lang
		})
	}

	const nearest_with_title = to.matched
		.slice()
		.reverse()
		.find(record => record.meta && record.meta.title)
	const nearest_with_meta = to.matched
		.slice()
		.reverse()
		.find(record => record.meta && record.meta.tags)

	if (nearest_with_title) {
		document.title = i18n.t(nearest_with_title.meta.title)
	}

	Array.from(document.querySelectorAll('[data-vue]')).map(el => {
		el.parentNode.removeChild(el)
	})

	if (!nearest_with_meta) {
		return next()
	}

	nearest_with_meta.meta.tags
		.map(tag_def => {
			let tag
			if (tag_def.hasOwnProperty('rel')) {
				tag = document.createElement('link')
			} else {
				tag = document.createElement('meta')
			}
			Object.keys(tag_def).forEach(key => {
				if (key === 'hreflang') {
					tag.setAttribute(key, i18n.t(tag_def[key], alt_lang))
				} else {
					tag.setAttribute(key, i18n.t(tag_def[key]))
				}
			})

			tag.setAttribute('data-vue', '')

			return tag
		})
		.forEach(tag => document.head.appendChild(tag))

	document.documentElement.lang = i18n.t(`common.lang-code`)

	next()
})

export default router
