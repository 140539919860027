import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

import './icons'

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
	id: 'UA-121181190-1',
	router,
	disabled: !store.getters.get_consent,
	checkDuplicatedScript: true,
	trackEvent: true,
	autoTracking: {
		skipSamePath: true,
		transformQueryString: false
	},
	commands: {
		trackClick(category, label) {
			this.$ga.event({
				eventCategory: category,
				eventAction: 'click',
				eventLabel: label
			})
		}
	}
})

new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
	name: 'Adam Killander'
}).$mount('#adam_killander')
