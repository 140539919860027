import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faHome,
	faUser,
	faTools,
	faCode,
	faBriefcase,
	faToggleOff,
	faToggleOn,
	faFlagAlt,
	faChartBar,
	faSitemap,
	faShoppingBasket,
	faTimes,
	faPaperPlane,
	faEnvelope
} from '@fortawesome/pro-light-svg-icons'

import {
	faGithubAlt,
	faLinkedinIn,
	faJsfiddle
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
	faHome,
	faUser,
	faTools,
	faCode,
	faBriefcase,
	faToggleOff,
	faToggleOn,
	faFlagAlt,
	faChartBar,
	faSitemap,
	faShoppingBasket,
	faTimes,
	faPaperPlane,
	faGithubAlt,
	faLinkedinIn,
	faJsfiddle,
	faEnvelope
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
	library
}
