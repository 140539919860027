<template>
	<footer id="footer" class="footer">
		<svg class="waves" id="floaty-mcwaves-aside" viewBox="0 0 100 15">
			<path fill="#ffffff" opacity="0.5" d="M0 30 V15 Q30 3 60 15 V30z" />
			<path fill="#fdfdff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />
		</svg>
		<div class="container nav">
			<ul class="list">
				<li v-for="({ name, icon }, key) in get_links" :key="key" class="point">
					<router-link
						:id="'nav-' + $t(name)"
						class="link"
						:to="{ name: name, params: { lang: get_language } }"
						exact
					>
						<font-awesome-icon :icon="['fal', $t(icon)]" fixed-width />
						<span class="name" v-t="name" />
					</router-link>
				</li>
			</ul>

			<div class="content">
				<h4 class="title" v-t="'common.footer.title'" />
				<p class="text" v-t="'common.footer.text'" />
				<a :href="`mailto:${$t('common.email')}`" class="email">
					<font-awesome-icon
						:icon="['fal', 'envelope']"
						size="lg"
						class="mr-2"
						fixed-width
					/>{{ $t('common.email') }}</a
				>
			</div>

			<a
				class="floaty"
				v-for="({ url, text, icon }, key) in social"
				:key="key"
				:href="url"
				:id="key"
				:title="text"
				target="_blank"
				rel="noopener"
				v-ga="$ga.commands.trackClick.bind($parent, 'social', text)"
			>
				<font-awesome-icon
					:icon="['fab', icon]"
					size="3x"
					fixed-width
					:title="text"
				/>
			</a>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: () => ({
		social: {
			linkedin: {
				url: 'https://www.linkedin.com/in/adam-killander-a91aa0103/',
				text: 'Linkedin',
				icon: 'linkedin-in'
			},
			github: {
				url: 'https://github.com/Adamkillander96?tab=repositories',
				text: 'Github',
				icon: 'github-alt'
			},
			jsfiddle: {
				url: 'https://jsfiddle.net/user/Adamkillander/fiddles/',
				text: 'JSFiddle',
				icon: 'jsfiddle'
			}
		}
	}),
	computed: {
		...mapGetters(['get_language', 'get_links'])
	}
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.footer {
	position: relative;
	color: $white;
	background-image: $lake;
	margin-top: 3em;
	.waves {
		transform: rotate(180deg);
		top: -1px;
	}
	.container {
		padding: 5em 1em;
		max-width: $max-width;
		text-align: center;
		margin: 0 auto;
		position: relative;
		z-index: 5;
		.point {
			flex: 1 1 33%;
			@media (max-width: $breakpoint) {
				flex: 1 1 100%;
			}
			.link {
				@media (max-width: $breakpoint) {
					text-align: left;
				}
				span {
					display: block;
					@media (max-width: $breakpoint) {
						display: inline-block;
						margin-left: 5px;
					}
				}
			}
		}
	}
	.content {
		padding: 2.5em;
	}
}
.floaty {
	display: inline-block;
	padding: 5px;
	color: $white;
}

@media (prefers-color-scheme: dark) {
	.footer {
		color: $white;
		background-image: $night;
	}
	.floaty {
		color: $white;
	}
}

.email {
	font-size: 0.8rem;
	color: $white;
	@media (prefers-color-scheme: dark) {
		color: $orange;
	}
	svg {
		margin-right: 0.3rem;
	}
}
</style>
