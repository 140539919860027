var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer",attrs:{"id":"footer"}},[_c('svg',{staticClass:"waves",attrs:{"id":"floaty-mcwaves-aside","viewBox":"0 0 100 15"}},[_c('path',{attrs:{"fill":"#ffffff","opacity":"0.5","d":"M0 30 V15 Q30 3 60 15 V30z"}}),_c('path',{attrs:{"fill":"#fdfdff","d":"M0 30 V12 Q30 17 55 12 T100 11 V30z"}})]),_c('div',{staticClass:"container nav"},[_c('ul',{staticClass:"list"},_vm._l((_vm.get_links),function(ref,key){
var name = ref.name;
var icon = ref.icon;
return _c('li',{key:key,staticClass:"point"},[_c('router-link',{staticClass:"link",attrs:{"id":'nav-' + _vm.$t(name),"to":{ name: name, params: { lang: _vm.get_language } },"exact":""}},[_c('font-awesome-icon',{attrs:{"icon":['fal', _vm.$t(icon)],"fixed-width":""}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:(name),expression:"name"}],staticClass:"name"})],1)],1)}),0),_c('div',{staticClass:"content"},[_c('h4',{directives:[{name:"t",rawName:"v-t",value:('common.footer.title'),expression:"'common.footer.title'"}],staticClass:"title"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('common.footer.text'),expression:"'common.footer.text'"}],staticClass:"text"}),_c('a',{staticClass:"email",attrs:{"href":("mailto:" + (_vm.$t('common.email')))}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'envelope'],"size":"lg","fixed-width":""}}),_vm._v(_vm._s(_vm.$t('common.email')))],1)]),_vm._l((_vm.social),function(ref,key){
var url = ref.url;
var text = ref.text;
var icon = ref.icon;
return _c('a',{directives:[{name:"ga",rawName:"v-ga",value:(_vm.$ga.commands.trackClick.bind(_vm.$parent, 'social', text)),expression:"$ga.commands.trackClick.bind($parent, 'social', text)"}],key:key,staticClass:"floaty",attrs:{"href":url,"id":key,"title":text,"target":"_blank","rel":"noopener"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', icon],"size":"3x","fixed-width":"","title":text}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }