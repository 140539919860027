import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

function load_locale_messages() {
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

let path = window.location.pathname

function array_contains(needle, arrhaystack) {
	for (let i = 0; i < arrhaystack.length; i++) {
		let key = arrhaystack.indexOf(needle)
		if (key !== -1) {
			return arrhaystack[key]
		}
	}
	return false
}

function get_all_languages() {
	let languagesArray = []
	locales.keys().forEach(locale => {
		languagesArray.push(locale.slice(2, -5))
	})
	return languagesArray
}
const languages = get_all_languages()

let userComingFrom = path.split('/')[1]
userComingFrom = array_contains(userComingFrom, languages)

export default new VueI18n({
	locale: userComingFrom ? userComingFrom : 'sv',
	availableLocales: languages,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	silentTranslationWarn: true,
	messages: load_locale_messages()
})
