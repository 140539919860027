<template>
	<div id="adam_killander">
		<Settings />
		<Nav />
		<main id="content" class="main">
			<router-view />
			<Aside v-if="check_for_cta.exists" :index="check_for_cta.index" />
			<button
				:title="$t('common.settings.toggle')"
				class="settings"
				:class="{ active: !get_menu }"
				@click="toggle_menu()"
			>
				<font-awesome-icon
					:icon="['fal', get_menu ? 'tools' : 'times']"
					size="3x"
					fixed-width
				/>
			</button>
		</main>
		<Footer />
	</div>
</template>

<script>
import Nav from '@/components/generic/nav.vue'
import Footer from '@/components/generic/footer.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'portfolio',
	components: {
		Nav,
		Aside: () =>
			import(/* webpackChunkName: "aside" */ '@/components/generic/aside.vue'),
		Footer,
		Settings: () =>
			import(
				/* webpackChunkName: "settings" */ '@/components/generic/settings.vue'
			)
	},
	computed: {
		...mapGetters(['get_menu']),
		check_for_cta() {
			return {
				exists: this.$te(`pages[${this.$route.meta.index}].cta.title`),
				index: this.$route.meta.index
			}
		}
	},
	methods: {
		...mapActions(['toggle_menu', 'create_links', 'check_cookie_consent'])
	},
	created() {
		this.create_links()
	},
	mounted() {
		this.check_cookie_consent()
	}
}
</script>

<style lang="scss" id="css-main">
@import '@/assets/variables.scss';
@import '@/assets/global.scss';

.row {
	display: flex;
	flex-flow: row wrap;
}
.section {
	padding: 1em;
	max-width: $max-width;
	margin: 0 auto;
	&:target h3 {
		color: $blue;
	}
}

.settings {
	padding: 1em;
	background: transparent;
	height: 76.66px;
	color: $black;
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 10;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: $grass;
		border-radius: 50%;
		z-index: -1;
		color: $white;
	}
	&.active {
		color: $white;
		border-color: darken($blue, 5%);
		&:before {
			background-image: $fire;
		}
	}
}

@media (prefers-color-scheme: dark) {
	.section {
		&:target h3 {
			color: $orange;
		}
	}
	.settings {
		color: $white;
		&:before {
			background-image: $gold;
		}
		&.active {
			border-color: darken($red, 5%);
			&:before {
				background: $red;
			}
		}
	}
}
</style>
