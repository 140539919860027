<template>
	<nav id="nav" class="nav">
		<ul class="list">
			<li v-for="({ name, icon }, key) in get_links" :key="key" class="point">
				<router-link
					:id="`nav-${pretty_id($t(name))}`"
					class="link"
					:to="{ name, params: { lang: get_language } }"
					exact
					:aria-labelledby="`page-${pretty_id($t(name))}`"
				>
					<font-awesome-icon :icon="['fal', $t(icon)]" fixed-width />
					<span class="name" :id="`page-${pretty_id($t(name))}`">{{
						$t(name)
					}}</span>
				</router-link>
			</li>
		</ul>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['get_language', 'get_links'])
	},
	methods: {
		pretty_id(name) {
			return name.replace(/ /g, '-').toLowerCase()
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

@media (max-width: 800px) {
	.point .name {
		display: none;
	}
	.nav .list .point {
		flex: 1 1 auto;
	}
}

.nav {
	position: absolute;
	z-index: 3;
	width: 100%;
	.list {
		display: flex;
		flex-flow: row wrap;
	}
	.point {
		flex: 0 1 auto;
		.link {
			width: 100%;
			padding: 0.5em;
			display: block;
			text-align: center;
			color: rgba($white, 0.75);
			&.active {
				color: $white;
				background: rgba($white, 0.25);
			}
		}
	}
}
</style>
